<template>
  <div class="confirm">
    <div>
      <div class="title">商品信息</div>
      <div class="item">
        <div class="itemTitle">
          <div class="titles">{{ item.name?item.name:item.courseName }}</div>
        </div>
        <div class="itemCover">
          <img :src="item.cover" alt="" />
        </div>
      </div>
    </div>
    <div v-if="!haveDiscount" style="padding: 20px 0">
      <el-form>
        <el-form-item label="优惠码" label-width="70px">
          <el-input v-model="couponKey" style="width: 80%"></el-input>
          <span class="getcode" @click="getDiscountPrice">立即兑换</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="confirmBtn">
      <div class="price">
        实付<span class="usdprice">${{ totalPrice }}</span>
        <span class="discount" v-if="haveDiscount"
          >(优惠:${{ discountPrice }})</span
        >
      </div>
      <div class="confirmOrder" @click="createOrder">提交订单</div>
    </div>
  </div>
</template>
<script>
import { getStorageForArray, dateFormat } from "@/utils/index";
import { getCouponDiscountPrice, createOrder } from "@/service/index";
import { Toast } from "vant";
// import {}
export default {
  name: "Confirm",
  data() {
    return {
      item: {},
      haveDiscount: false,
      couponKey: "",
      discountPrice: "",
      totalPrice: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log(JSON.parse(getStorageForArray("order")),'JSON.parse(getStorageForArray("order"))');
      this.item = JSON.parse(getStorageForArray("order"));
      this.totalPrice = this.item.usdprice?this.item.usdprice :this.item.usdAmount;
    },
    getDiscountPrice() {
      if (this.couponKey == "") {
        return Toast("请输入优惠码!");
      }
      let data = {
        code: this.couponKey,
        courseClassIds: [this.item.courseClassId],
      };
      getCouponDiscountPrice(data).then((res) => {
        if (res.success) {
          this.haveDiscount = true;
          this.discountPrice = res.result;
          this.totalPrice = this.totalPrice - this.discountPrice;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    createOrder() {
      createOrder({
        details: [{ classId: this.item.courseClassId, comboId: this.item.id }],
        code: this.couponKey,
        createTime: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
      })
        .then((res) => {
          if (res.success) {
            // 跳到选择支付方式组建中
            this.$router.push({
              path: "/pay/order",
              query: { orderId: res.result.orderId },
            });
          } else {
            Toast(res.message);
          }
        })
        .catch((err) => {
          Toast("订单创建失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.confirm {
  margin-top: 54px;
  .title {
    padding: 12px 20px;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #979797;
    line-height: 14px;
    border-bottom: 1px solid #eeeeee;
  }
  .item {
    padding: 12px 20px;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #eeeeee;
    .itemTitle {
      width: 48%;
      margin-right: 2%;
      position: relative;
      .titles {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 18px;
      }
    }
    .itemCover {
      width: 50%;
      img {
        width: 100%;
        vertical-align: top;
        border-radius: 4px;
      }
    }
  }
}
.confirmBtn {
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -4px 4px 1px rgba(195, 195, 195, 0.2);
  display: flex;
  flex-wrap: nowrap;
  .price {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 14px;
    width: calc(100% - 150px);
    line-height: 56px;
    padding-left: 20px;
  }
  .usdprice {
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #f14141;
    margin-left: 6px;
  }
  .confirmOrder {
    background: linear-gradient(135deg, #ff5935 0%, #d80404 100%);
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
    width: 130px;
    text-align: center;
  }
  .discount {
    font-size: 12px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #f14141;
    margin-left: 6px;
  }
}
.getcode {
  margin-left: 6px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #f14141;
  text-decoration: underline;
}
</style>