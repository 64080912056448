import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=557161d8&scoped=true"
import script from "./Confirm.vue?vue&type=script&lang=js"
export * from "./Confirm.vue?vue&type=script&lang=js"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=557161d8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557161d8",
  null
  
)

export default component.exports